@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #061425;
  --accent: #f4d35e;
  --accent2: #ef4444;
  --accent3: #0ea5e9;
  --background: #303a44;

  --primaryDark: #191919;
  --highlight: #e80000;
  --whiteColor: #f7f7ff;
  --blackColor: #000814;
  --greyColor: #c5c5c5;
  --transparent: transparent;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #061425;
    --accent: #f4d35e;
    --accent2: #ef4444;
    --accent3: #0ea5e9;
    --background: #303a44;

    --primaryDark: #191919;
    --highlight: #e80000;
    --whiteColor: #f7f7ff;
    --blackColor: #000814;
    --greyColor: #c5c5c5;
    --transparent: transparent;
  }
}
::selection {
  background: var(--accent);
}

@font-face {
  font-family: "Sylexiad";
  src: url("/fonts/SylexiadSansMedium.ttf");
  font-weight: normal;
  font-style: normal;
}

@layer components {
  h1 {
    word-break: break-all;
    letter-spacing: 1.75pt;
    word-break: keep-all;
    @apply text-7xl lg:text-9xl drop-shadow-md;
  }

  h2 {
    @apply text-4xl md:text-7xl drop-shadow-md;
  }

  h3 {
    @apply text-xl md:text-5xl drop-shadow-md;
  }

  h4 {
    @apply text-2xl md:text-3xl drop-shadow-md;
  }

  h5 {
    @apply text-xl md:text-2xl drop-shadow-md;
  }

  h6 {
    @apply text-lg md:text-xl;
  }

  p {
    @apply text-base;
  }

  label {
    @apply text-xl font-bold;
  }

  body {
    background-color: #f3f3f3;
    color: #727272;
    font-family: "Sylexiad", sans-serif !important;
  }

  .site-core {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .site-content {
    position: relative;
    z-index: 10;
    overflow: hidden;
    background-color: pink;
    transition: opacity 0.3s ease-in;
    height: 100vh;
  }
}
