.Header {
  &_container {
    position: sticky;
    top: 40px; // for the sticky.
    z-index: 1;
    margin-top: 50px;
  }
  &_root {
    position: sticky;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    left: 0;
    z-index: 1; // allow mouse events on the header.
    height: 0;
  }
  &_content {
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 100px;
    transition: background-color 0.1s linear;
    width: 100%;
    &.scrolled {
      background-color: var(--grey);
    }
  }
  &_contactDetails {
    z-index: 1;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    background-color: var(--secondaryAccent);
  }
  &_contentDetailsContent {
    display: flex;
    & > h6 {
      color: var(--whiteColor);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &_contentDetailsSocialMedia {
    margin-left: 20px;
    align-self: center;
  }
  &_contentInner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 1000px;
  }
  &_logoContainer {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_logo {
    min-width: 50px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &.hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  &_company {
    display: flex;
    align-items: center;
    font-size: toRem(14px);
    letter-spacing: 0px;
    color: #fff;
    padding-right: 80px;
    h4 {
      color: #fff;
    }
    &.scrolled {
      h4 {
        color: #0f0f0f;
      }
    }
  }
  &_menu {
    display: flex;
    margin-left: 50px;
  }
  &_mobile {
    display: none;
    justify-content: flex-end;
    padding-left: 20px;
  }
  &_mobileView {
    height: 100vh;
    width: 100%;
    background-color: white;
  }
  &_mobileMenu {
    display: flex;
    flex-direction: column;
  }
  &_mobileViewInner {
    height: 100%;
    padding-top: 60px;
    padding-top: 80px;
  }
  &_arrowIcon {
    margin-left: 10px;
  }
  &_contactDetailsIcon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 30px;
    margin-right: 15px;
    color: var(--accent);
  }
}

@media screen and (max-width: 1232px) {
  .Header {
    &_root {
      position: relative;
      height: unset;
      top: 0;
    }
    &_content {
      min-width: 0px;
      flex-direction: column;
    }
    &_contentInner {
      height: unset;
      display: none; //TODO header widget
    }
    &_mobile {
      display: flex;
      justify-content: space-between;
      h4 {
        color: #fff;
        font-size: 25px;
      }
      &.scrolled {
        h4 {
          color: #0f0f0f;
        }
      }
      &.showMenu {
        h4 {
          color: #0f0f0f;
        }
      }
    }
    &_container {
      position: sticky;
      top: 0;
      z-index: 1;
      margin-top: 0;
    }
    &_contactDetails {
      display: none;
    }
    &_logo {
      margin-top: 15px;
    }
  }
}

// Logo sizing
@media screen and (max-width: 550px) {
  .Header {
    &_logo {
      height: 20px;
    }
  }
}
