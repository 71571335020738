@import "Animations.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #061425;
  --accent: #f4d35e;
  --accent2: #ef4444;
  --accent3: #0ea5e9;
  --background: #303a44;

  --primaryDark: #191919;
  --highlight: #e80000;
  --whiteColor: #f7f7ff;
  --blackColor: #000814;
  --greyColor: #c5c5c5;
  --transparent: transparent;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #061425;
    --accent: #f4d35e;
    --accent2: #ef4444;
    --accent3: #0ea5e9;
    --background: #303a44;

    --primaryDark: #191919;
    --highlight: #e80000;
    --whiteColor: #f7f7ff;
    --blackColor: #000814;
    --greyColor: #c5c5c5;
    --transparent: transparent;
  }
}
::selection {
  background: var(--accent);
}
/* 
@font-face {
  font-family: "Sylexiad";
  src: url("/fonts/SylexiadSansMedium.ttf");
  font-weight: normal;
  font-style: normal;
} */

@mixin ButtonChrome {
  color: #ffffff;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: var(--secondaryAccent);
  border: 0px solid transparent;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;

  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;

  transition-property: filter, transform, box-shadow;
  transition-timing-function: ease-in-out;
  transition-duration: 0s;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

  &:hover {
    background-color: var(--secondaryAccentLight);
    color: #fff;
  }

  &.small {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 28px;
    font-size: 0.9rem;
  }

  &.alt {
    padding: 10px;
    background-color: transparent;
    border: 3px solid #fff;
    &:hover {
      background-color: #fff;
      color: #0f0f0f;
      > .ButtonBehaviour_icon {
        filter: brightness(0) invert(0);
      }
    }
    &.scrolled {
      > .ButtonBehaviour_icon {
        filter: brightness(0) invert(0);
      }
    }
    &.showMenu {
      > .ButtonBehaviour_icon {
        border: 3px solid transparent;
        filter: brightness(0) invert(0);
        &:hover {
          border: 3px solid #0f0f0f;
        }
      }
    }
  }
}

@layer components {
  h1 {
    word-break: break-all;
    letter-spacing: 1.75pt;
    word-break: keep-all;
    @apply text-7xl lg:text-9xl drop-shadow-md;
  }

  h2 {
    @apply text-4xl md:text-7xl drop-shadow-md;
  }

  h3 {
    @apply text-xl md:text-5xl drop-shadow-md;
  }

  h4 {
    @apply text-2xl md:text-3xl drop-shadow-md;
  }

  h5 {
    @apply text-xl md:text-2xl drop-shadow-md;
  }

  h6 {
    @apply text-lg md:text-xl;
  }

  p {
    @apply text-base;
  }

  label {
    @apply text-xl font-bold;
  }

  body {
    background-color: #f3f3f3;
    color: #727272;
    font-family: "Sylexiad", sans-serif !important;
  }

  .site-core {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .site-content {
    position: relative;
    z-index: 10;
    overflow: hidden;
    background-color: pink;
    transition: opacity 0.3s ease-in;
    height: 100vh;
  }
}
